import { configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./cart.slice";
import { baseApi } from "./baseApi";
import {setupListeners} from "@reduxjs/toolkit/query/react";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const reducers = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  cart: cartReducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [baseApi.reducerPath]
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(
      baseApi.middleware
    )
});

setupListeners(store.dispatch);

export default store;

export let persistor = persistStore(store);
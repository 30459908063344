import {redirectToCDN} from "./urlHandlers";

export const formatCurrency = number => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(number);
};

export const mutationQueryWrapper = (query) => {
  return '{"query": "' + query.replace('"', "\"").trim() + '"}';
}
export const metatagFactory = (tagType, attributes) => {

  const scrubbedAttributes = [...attributes, {name: "key", value: `${tagType}-${Math.random().toString(36).substr(2, 5)}`}].map((attribute) => {

    return {...attribute, value: process.env.NEXT_PUBLIC_BASE_URL !== undefined ? attribute.value.replace(process.env.NEXT_PUBLIC_ORIGIN_URL, process.env.NEXT_PUBLIC_BASE_URL) : attribute.value}
  }).reduce((newProps, attribute) => {

    return {...newProps, [attribute.name]: attribute.value}
  }, {});

  switch(tagType) {
    case 'link':
      return <link {...scrubbedAttributes} />;
    case 'meta':
      return <meta {...scrubbedAttributes} />;
  }
}

export const formatMetatags = (metaTags) => {
  return metaTags.map((metaTag) => {
    return metatagFactory(metaTag.tag, metaTag.attributes);
  })
}

export const setCannonicalLink = (metatags, url) => {
  const canonicalLinkRemoved = metatags.filter((metatag) => !(metatag.tag === "link" && metatag.attributes[0].value === "canonical"));
  const canonicalLinkMeta = {
    tag: "link",
    attributes: [
      {
        name: "rel",
        value: "canonical"
      },
      {
        name: "href",
        value: url
      }
    ]
  }

  return [canonicalLinkMeta, ...canonicalLinkRemoved];
}

export const formatFullname = (firstName, lastName) => {

  if(firstName !== undefined && lastName !== undefined) {
    return `${firstName} ${lastName}`;
  }
  if(lastName === undefined) {
    return firstName;
  }

  if(firstName === undefined) {
    return lastName;
  }

  return "";
}
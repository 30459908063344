// TODO: Should separate shop, checkout and cart actions. Don't have it in me to do it right now.

import { baseApi } from '../../../redux/baseApi';
import {mutationQueryWrapper} from "../../utils/formatters";
import {
  updateShippingProfile,
  updateBillingProfile,
  calculateShippingRatesQuery,
  shippingApplyRateQuery,
  updateCardMessageQuery,
  createPaymentQuery,
  placeOrderQuery
} from "../../Shop/_queries";

import {
  createCartQuery,
  addToCartQuery,
  getCartByIdQuery,
  updateOrderItemQuantityQuery,
  deleteOrderItemQuery,
  applyCouponToCartQuery,
} from "../_queries";

const cartApi = baseApi.enhanceEndpoints({addTagTypes: ['Cart']})

export const cartApiSlice = cartApi.injectEndpoints({
  endpoints: (build) => ({
    getCartById: build.query({
      query: ({cartId}) => ({
        url: "/",
        method: 'GET',
        headers: {'Content-Type': 'application/graphql'},
        params: {
          query: getCartByIdQuery.replace("$cartId", cartId).replace(/\s+/g, ' ')
        }
      }),
      transformResponse: (response) => response.data.commerceOrder,
      providesTags: ['Cart']
    }),
    createCart: build.mutation({
      query: (variationId) => ({
        method: 'POST',
        headers: { 'Content-Type': 'application/graphql'},
        body: mutationQueryWrapper(createCartQuery.replace("$variationId", variationId).replace(/\s+/g, ' '))
      }),
      transformResponse: (response) => response.data.commerceOrderCreateCart.order.uuid,
    }),
    addToCart: build.mutation({
      query: ({cartId, variationUuid}) => ({
        method: 'POST',
        headers: {'Content-Type': 'application/graphql'},
        body: mutationQueryWrapper(
          addToCartQuery
            .replace("$cartId", cartId)
            .replace("$variationUuid", variationUuid)
            .replace(/\s+/g, ' ')
        )
      }),
      transformResponse: (response) => response.data.commerceCartAddItem,
      invalidatesTags: ['Cart']
    }),
    updateOrderItemQuantity: build.mutation({
      query: ({orderItemUuid, quantity}) => ({
        method: 'POST',
        headers: {'Content-Type': 'application/graphql'},
        body: mutationQueryWrapper(
          updateOrderItemQuantityQuery
            .replace("$orderItemId", orderItemUuid)
            .replace("$quantity", quantity)
            .replace(/\s+/g, ' ')
        )
      }),
      transformResponse: (response) => response.data.commerceCartUpdateQuantity,
      invalidatesTags: ['Cart']
    }),
    removeOrderItem: build.mutation({
      query: ({orderItemUuid}) => ({
        method: 'POST',
        headers: {'Content-Type': 'application/graphql'},
        body: mutationQueryWrapper(
          deleteOrderItemQuery
            .replace("$orderItemUuid", orderItemUuid)
            .replace(/\s+/g, ' ')
        )
      }),
      invalidatesTags: ['Cart']
    }),
    applyCouponToCart: build.mutation({
      query: ({cartId, couponCode}) => ({
        method: 'POST',
        headers: {'Content-Type': 'application/graphql'},
        body: mutationQueryWrapper(
          applyCouponToCartQuery
            .replace("$cartId", cartId)
            .replace("$couponCode", couponCode)
            .replace(/\s+/g, ' ')
        )
      }),
      transformResponse: (response) => response.data.commerceCouponRedeem,
      invalidatesTags: (response) => {
        // If the backend returned errors, don't refresh the cart.
        return (response.errors.length > 0) ? [] : ['Cart'];
      }
    }),
    updateShippingProfile: build.mutation({
      query: ({orderUuid, street, city, administrativeArea, countryCode, postalCode, firstName, lastName, phoneNumber, street2='', organization='', email='' }) => {
        return {
          method: 'POST',
          headers: {'Content-Type': 'application/graphql'},
          body: mutationQueryWrapper(
            updateShippingProfile
              .replace("$orderUuid", orderUuid)
              .replace("$street", street)
              .replace("$street2", street2)
              .replace("$city", city)
              .replace("$administrativeArea", administrativeArea)
              .replace("$countryCode", countryCode)
              .replace("$postalCode", postalCode)
              .replace("$organization", organization)
              .replace("$firstName", firstName)
              .replace("$lastName", lastName)
              .replace("$phoneNumber", phoneNumber)
              .replace("$email", email)
              .replace(/\s+/g, ' ')
          )
        }
      },
      transformResponse: (response) => {
        return response.data.commerceOrderUpdateShippingProfile
      },
    }),
    updateBillingProfile: build.mutation({
      query: ({orderUuid, street, city, administrativeArea, countryCode, postalCode, firstName, lastName, phoneNumber, street2='', organization='', email='' }) => {
        return {
          method: 'POST',
          headers: {'Content-Type': 'application/graphql'},
          body: mutationQueryWrapper(
            updateBillingProfile
              .replace("$orderUuid", orderUuid)
              .replace("$street", street)
              .replace("$street2", street2)
              .replace("$city", city)
              .replace("$administrativeArea", administrativeArea)
              .replace("$countryCode", countryCode)
              .replace("$postalCode", postalCode)
              .replace("$organization", organization)
              .replace("$firstName", firstName)
              .replace("$lastName", lastName)
              .replace("$phoneNumber", phoneNumber)
              .replace("$email", email)
              .replace(/\s+/g, ' ')
          )
        }
      },
      transformResponse: (response) => response.data.commerceOrderUpdateBillingProfile
    }),
    updateCardMessage: build.mutation ({
      query: ({orderUuid, message}) => {
        return {
          method: 'POST',
          headers: {'Content-Type': 'application/graphql'},
          body: mutationQueryWrapper(
            updateCardMessageQuery
              .replace("$orderUuid", orderUuid)
              .replace("$message", message)
              .replace(/\s+/g, ' ')
          )
        }
      },
      transformResponse: (response) => response.data.commerceOrderUpdateCardMessage
    }),
    calculateShippingRates: build.mutation({
      query: ({shipmentUuid}) => {

        return {
          method: 'POST',
          headers: {'Content-Type': 'application/graphql'},
          body: mutationQueryWrapper(
            calculateShippingRatesQuery
              .replace("$shipmentUuid", shipmentUuid)
              .replace(/\s+/g, ' '))
        }
      },
      transformResponse: (response) => response.data.commerceOrderCalculateShippingRates,
    }),
    applyShippingRates: build.mutation({
      query: ({shipmentUuid, rateId, expectedDeliveryDate}) => {

        return {
          method: 'POST',
          headers: {'Content-Type': 'application/graphql'},
          body: mutationQueryWrapper(
            shippingApplyRateQuery
              .replace("$shipmentUuid", shipmentUuid)
              .replace("$rateId", rateId)
              .replace("$expectedDeliveryDate", expectedDeliveryDate)
              .replace(/\s+/g, ' '))
        }
      },
      transformResponse: (response) => response.data.commerceShipmentApplyRate,
    }),
    createPayment: build.mutation({
      query: ({orderUuid, nonce, paymentToken, last4, cardType, expMonth, expYear}) => {
        return {
          method: 'POST',
          headers: {'Content-type': 'application/graphql'},
          body: mutationQueryWrapper(
            createPaymentQuery
              .replace("$orderUuid", orderUuid)
              .replace("$nonce", nonce)
              .replace("$paymentToken", paymentToken)
              .replace("$last4", last4)
              .replace("$cardType", cardType)
              .replace("$expMonth", expMonth)
              .replace("$expYear", expYear)
              .replace(/\s+/g, ' ')
          )
        }
      },
      transformResponse: (response) => response.data.commercePaymentCreateSquare,
    }),
    placeOrder: build.mutation({
      query: ({orderUuid}) => {
        return {
          method: 'POST',
          headers: {'Content-type': 'application/graphql'},
          body: mutationQueryWrapper(
            placeOrderQuery
              .replace("$orderUuid", orderUuid)
              .replace(/\s+/g, ' ')
          )
        }
      },
      //transformResponse: (response) => response.data.commercePlaceOrder,
    })
  })
});

export const {
  useCreateCartMutation,
  useAddToCartMutation,
  useUpdateOrderItemQuantityMutation,
  useRemoveOrderItemMutation,
  useApplyCouponToCartMutation,
  useGetCartByIdQuery,
  useUpdateShippingProfileMutation,
  useUpdateBillingProfileMutation,
  useCalculateShippingRatesMutation,
  useApplyShippingRatesMutation,
  useUpdateCardMessageMutation,
  useCreatePaymentMutation,
  usePlaceOrderMutation
} = cartApiSlice;

import React from 'react';
import Header from './Header';
import dynamic from 'next/dynamic';
const Footer = dynamic(() => import('./Footer'));

export default class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className='page-wrapper'>
        <Header />
        { children }

        <Footer />
      </div>
    )
  }
}
import {Provider} from 'react-redux';
import Layout from '../components/layout';
import store, { persistor } from "../redux/store";

import ErrorBoundary from "../components/utils/ErrorBoundary";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { GoogleTagManager } from '@next/third-parties/google';

config.autoAddCss = false;
import '../styles/globals.scss'

function MyApp({ Component, pageProps }) {

  return (
    <Provider store={store}>
        <Layout>
          <ErrorBoundary>
            <Component {...pageProps} />

          </ErrorBoundary>
        </Layout>
    </Provider>
  )
}

export default MyApp;

import {createSlice} from "@reduxjs/toolkit";
import {cartApiSlice} from "../components/Cart/redux/cartApi";

const initialState = {
  cartId: null,
  items: [],
  progressiveOrder: null,
  orderRecipient: [],
  orderSender: [],
  serviceArea: null,
  senderSameAsRecipient: false,
  previousOrderId: null,
  cartStages: [
    {
      stage: "recipient",
      label: "Recipient",
      state: "unfulfilled",
      path: "/cart/checkout",
      weight: 10
    },
    {
      stage: "delivery",
      label: "Delivery",
      state: "unfulfilled",
      path: "/cart/delivery",
      weight: 20
    },
    {
      stage: "sender",
      label: "Sender",
      state: "unfulfilled",
      path: "/cart/sender",
      weight: 30
    },
    {
      stage: "options",
      label: "Options",
      state: "unfulfilled",
      path: "/cart/options",
      weight: 40
    },
    {
      stage: "review",
      label: "Review & Pay",
      state: "unfulfilled",
      path: "/cart/review",
      weight: 50
    },
    {
      stage: "finish",
      label: "Done!",
      state: "unfulfilled",
      path: "/cart/finished",
      weight: 100
    }
  ]
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    purgeCart: (state, action) => {
      return initialState;
    },

    // Same as purge, but lets you store info as you're nuking the session
    resetCart: (state, action) => {

      return {...initialState, ...action.payload};
    },
    /*
      For security reasons, the backend does not return address information. We need to store
      it separately in order to confirm it later. This is a feature.
     */
    setOrderRecipient: (state, action) => {
      return {...state, orderRecipient: action.payload}
    },
    setOrderSender: (state, action) => {
      return {...state, orderSender: action.payload}
    },
    toggleSenderSameAsRecipient: (state, action) => {
      return {...state, senderSameAsRecipient: !state.senderSameAsRecipient}
    },
    advanceCheckoutStage: (state, action) => {

      const stages = state.cartStages;
      const currentStageIndex = stages.findIndex(stage => stage.path === action.payload);
      const updatedStages = [...stages.filter(stage => stage.stage !== stages[currentStageIndex].stage), {...stages[currentStageIndex], state: "fulfilled"}].sort((a, b) => {return a.weight - b.weight});

      return {...state, cartStages: updatedStages};
    },
    updateProgressiveOrder: (state, action) => {
      return {...state, progressiveOrder: action.payload}
    }
  },
  extraReducers : (builder) => {
    builder
      .addMatcher(
        cartApiSlice.endpoints.createCart.matchFulfilled,
        (state, {payload}) => {
          return {...state, cartId: payload};
        }
      )
      .addMatcher(
        cartApiSlice.endpoints.updateShippingProfile.matchFulfilled,
        (state, {payload}) => {

          return payload.order.shipments[0].shippingProfile.serviceArea !== undefined
            ? {...state, serviceArea: payload.order.shipments[0].shippingProfile.serviceArea}
            : state;
        }
      )
  }
});

export const cartReducer = cartSlice.reducer;

export const {
  purgeCart,
  resetCart,
  advanceCheckoutStage,
  setOrderRecipient,
  setOrderSender,
  toggleSenderSameAsRecipient,
  updateProgressiveOrder
} = cartSlice.actions;
import {orderResponseTemplate} from "../Shop/_queries";

// TODO: I don't know if this query is being used anywhere. Evaluate and remove if not.
export const cartVariationsQuery = `
query {
  commerceVariationIndex(uuid:["$variationIds"]) {
    items {
      uuid
      variationId
      url
      title
      tagline
      price {
        number
      }
      listPrice {
        number
      }
      product{
        title
      }
      thumbnail {
        alt
        title
        derivatives {
          height
          width
          url
        }
      }
      shippingRange
    }
  }
}
`

export const createCartQuery = `
mutation{
  commerceOrderCreateCart (data:{ productVariations:[{ variationUuid:\\"$variationId\\" }] }){
    order {
      uuid
    }
  }
}
`;

export const addToCartQuery = `
mutation {
  commerceCartAddItem(
    orderUuid:\\"$cartId\\",
    data:{variationUuid:\\"$variationUuid\\"}
  ) {
    errors
    order {
      uuid
      items {
        title
        unitPrice{
          number
        }
        quantity
      }
    }
  }
}
`;

export const updateOrderItemQuantityQuery = `
mutation {
  commerceCartUpdateQuantity(orderItemUuid:\\"$orderItemId\\", quantity:$quantity){
    order{
      ${orderResponseTemplate}
    }
  }
}
`;

export const deleteOrderItemQuery = `
mutation {
  commerceCartRemoveItem(orderItemUuid:\\"$orderItemUuid\\"){
    order {
      ${orderResponseTemplate}
    }
  }
}
`

export const getCartByIdQuery = `
query {
  commerceOrder(uuid:"$cartId") {
    order {
      ${orderResponseTemplate}
    }
    errors
  }
}
`;

export const applyCouponToCartQuery = `
mutation {
  commerceCouponRedeem(orderUuid:\\"$cartId\\", couponCode:\\"$couponCode\\") {
    order {
      ${orderResponseTemplate}
    }
    errors
  }
}
`;

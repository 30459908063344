import { useRouter } from 'next/router'
import Link from 'next/link'
import React, { Children } from 'react'

const ActiveLink = ({ children, activeClassName, ...props }) => {
  const { asPath } = useRouter();
  const className = props.className || '';
  const activeClass = activeClassName || 'active';

  // pages/index.js will be matched via props.href
  // pages/about.js will be matched via props.href
  // pages/[slug].js will be matched via props.as
  const wrapperClassName =
    asPath === props.href || asPath === props.as
      ? `${className} ${activeClass}`.trim()
      : className;

  return (
    <Link className={className} {...props}>
      {children}
    </Link>
  )
};

export default ActiveLink;
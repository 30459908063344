import React from 'react';
import Link from 'next/link';
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetCartByIdQuery } from "./redux/cartApi";
import {useSelector} from "react-redux";
import {CartShopping} from '@styled-icons/fa-solid';

import styles from './styles/CartIcon.module.scss';


export default function CartIcon () {
  const calculateTotalItems = () => {
    const cartId = useSelector((state) => state.cart.cartId);
    const { data, error, isError } = useGetCartByIdQuery({cartId} ?? skipToken);

    if(isError || !cartId || !data) return 0;

    return data && data.order.items.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0);
  }

  const totalItems = calculateTotalItems();

  return (
    <div className={`${styles.cartIconWrapper}`}><Link href="/cart"><CartShopping size={35} />{totalItems}</Link></div>
  )
}
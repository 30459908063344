import React from 'react';
import Image from 'next/image';
import ActiveLink from "../utils/ActiveLink";
import {XMark as Close} from '@styled-icons/heroicons-outline';
import {PhoneFlip as Phone} from "@styled-icons/fa-solid"

import styles from './styles/MobileMenu.module.scss';
import Link from "next/link";

const MobileMenu = ({closeHandler}) => {
  return (
    <div className={styles.mobileNavWrapper}>
      <div className="text-right p-5">
        <Close
          size={48}
          onClick={() => closeHandler(false)}
        />
      </div>
      <div className="mb-10">
        <Image
          className="mx-auto"
          src="/images/logo.png"
          alt="Bespoke Blossoms Logo"
          width={100}
          height={100}
        />
      </div>
      <div className="mb-14">
        <nav>
          <ul>
            <li><ActiveLink href="/" onClick={() => closeHandler(false)}>Home</ActiveLink></li>
            <li><ActiveLink href="/shop" onClick={() => closeHandler(false)}>Shop</ActiveLink></li>
            <li><ActiveLink href="/weddings" onClick={() => closeHandler(false)}>Weddings</ActiveLink></li>
            <li><ActiveLink href="/about" onClick={() => closeHandler(false)}>About</ActiveLink></li>
            <li><ActiveLink href="/contact" onClick={() => closeHandler(false)}>Contact</ActiveLink></li>
          </ul>
        </nav>
      </div>
      <div className="text-center">
        <h3 className="text-3xl">Have a question?</h3>
        <Link className="button primary" href="tel:2503820243"><Phone size={24} /> 250-382-0243</Link>
      </div>
    </div>
  )
}

export default MobileMenu;
export const productIndexQuery = `
query{
  commerceProductIndex (sortBy:"field_sort_weight",limit:50){
    items {
      uuid
      title
      type
      badges {
        uuid
        name
        type
      }
      collections {
        uuid
        name
      }
      searchType {
        uuid
        name
      }
      featured
      soldOut
      sortWeight
      thumbnail {
        alt
        title
        derivatives {
          label
          url
          width
          height
        }
      }
      url
      variations {
        uuid
        url
        listPrice {
          number
          currencyCode
        }
        price {
          number
          currencyCode
        }
        status
      }
    } 
  }
}
`;

export const productPageQuery = `
query{
  commerceProduct (path:"$route"){
    product {
      uuid
      title
      subcopy
      body
      type
      badges {
        uuid
        name
        type
      }
      collections {
        uuid
        name
      }
      searchType {
        uuid
        name
      }
      metatags {
        tag
        attributes {
          name
          value
        }
      }
      featured
      soldOut
      discontinued
      isInSeason
      season {
        name
      }
      url
      thumbnail {
        alt
        title
        derivatives {
          label
          url
          width
          height
        }
      }
      variations {
        uuid
        variationId
        title
        tagline
        sku
        url
        color
        stemCount
        shippingRange
        images{
          title
          alt
          derivatives {
            url
            width
            height
            label
          }
        }
        listPrice {
          number
          currencyCode
        }
        price {
          number
          currencyCode
        }
        dimensions {
          height
          length
          width
          units
        }
        weight{
          weight
          units
        }
        status
      }
    } 
  }
}
`;

export const orderResponseTemplate = `
  uuid
  orderId
  orderNumber
  isCart
  expectedDeliveryDate
  cardMessage
  subTotalPrice {
    number
  }
  totalPrice {
    number
  }
  shipments {
    uuid
    shippingProfile {
      serviceArea
    }
    method
    service
    amount {
      number
    }
    adjustments {
      type
      label
      amount{
        number
        currencyCode
      }
      percentage
      isIncludedInBasePrice
    }
    originalAmount {
      number
    }
  }
  items {
    uuid
    type
    title
    adjustments {
      type
      label
      amount {
        number
        currencyCode
      }
      percentage
      isIncludedInBasePrice
    }
    item{
      uuid
      url
      sku
      price {
        number
      }
      listPrice {
        number
      }
      product{
        title
      }
      thumbnail {
        alt
        title
        derivatives {
          height
          width
          url
        }
      }
      shippingRange    
    }
    quantity
    unitPrice {
      number
    }
    totalPrice {
      number
    }
  }
`;

export const createAddressQuery = `
mutation {
  createAddress(orderUuid:\\"$orderUuid\\", data:{
    address:{
      street:\\"$street\\"
      street2: \\"$street2\\"
      city:\\"$city\\"
      administrativeArea:\\"$administrativeArea\\"
      country:\\"$countryCode\\"
      postalCode:\\"$postalCode\\"
      organization:\\"$organization\\"
      firstName:\\"$firstName\\"
      lastName:\\"$lastName\\"
    },
    phoneNumber:\\"$phoneNumber\\"
  }) {
    errors
    profile {
      uuid
      serviceArea
    }
  }
}
`;

export const updateShippingProfile = `
mutation {
  commerceOrderUpdateShippingProfile(orderUuid:\\"$orderUuid\\", data:{
    address:{
      street:\\"$street\\"
      street2: \\"$street2\\"
      city:\\"$city\\"
      administrativeArea:\\"$administrativeArea\\"
      country:\\"$countryCode\\"
      postalCode:\\"$postalCode\\"
      organization:\\"$organization\\"
      firstName:\\"$firstName\\"
      lastName:\\"$lastName\\"
    },
    phoneNumber:\\"$phoneNumber\\",
    email: \\"$email\\"
  }){
    errors
    order{
      ${orderResponseTemplate}
    }
  }
}
`;

export const calculateShippingRatesQuery = `
mutation {
  commerceOrderCalculateShippingRates(shipmentUuid:\\"$shipmentUuid\\"){
    errors
    rates {
      id
      service
      amount { number }
      originalAmount { number }
      timeInTransit
      method
    }
  }
}
`;

export const shippingApplyRateQuery = `
  mutation{
    commerceShipmentApplyRate(shipmentUuid:\\"$shipmentUuid\\", shippingRateId:\\"$rateId\\", deliveryDate:\\"$expectedDeliveryDate\\") {
      errors
      shipment {
        uuid
      }
    }
  }
`;

export const dateOverridesQuery = `
query {
  dateOverrideIndex {
    total
    items {
      uuid
      title
      status
      startDate
      endDate
      timezone
      rrule
      shopState
      stores {
        uuid
        name 
      }
      weight
    }
  }
}
`;

export const updateBillingProfile = `
mutation {
  commerceOrderUpdateBillingProfile(
    orderUuid:\\"$orderUuid\\"
    data:{
      address:{
        firstName:\\"$firstName\\"
        lastName:\\"$lastName\\"
        street:\\"$street\\"
        street2:\\"$street2\\"
        city:\\"$city\\"
        administrativeArea:\\"$administrativeArea\\"
        country:\\"$countryCode\\"
        postalCode:\\"$postalCode\\"
        organization:\\"$organization\\"
      }
      email:\\"$email\\"
      phoneNumber:\\"$phoneNumber\\"
    }
  ) {
    errors
    order{
      ${orderResponseTemplate}
    }
  }
}
`;

export const updateCardMessageQuery = `
mutation{
  commerceOrderUpdateCardMessage(orderUuid:\\"$orderUuid\\", message:\\"$message\\"){
    errors
    order{
      ${orderResponseTemplate}
    }
  }
}
`;

export const createPaymentQuery = `
mutation {
  commercePaymentCreateSquare(orderUuid:\\"$orderUuid\\", cardInfo:{
    nonce:\\"$nonce\\",
    paymentToken:\\"$paymentToken\\",
    last4:\\"$last4\\",
    cardType:\\"$cardType\\",
    expMonth:\\"$expMonth\\",
    expYear:\\"$expYear\\"
  }) {
    errors
    order {
      uuid
      payments {
        state
        amount{
          number
        }
        paymentGateway
        authorizedDate
        isCompleted
        completedDate
        remoteId
      }
    }
  }
}
`;

export const placeOrderQuery = `
mutation {
  commerceOrderPlace(orderUuid:\\"$orderUuid\\"){
    errors
    statusCode
    order {
      uuid
      state
    }
  }
}
`

export const productPathsQuery = `
query {
  commerceProductIndex(limit:100){
    total
    items{
      url
    }
  }
}
`;
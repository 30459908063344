import React, {useState} from 'react';
import ActiveLink from "./utils/ActiveLink";

import CartIcon from "./Cart/CartIcon";
import MobileMenu from "./Shared/MobileMenu";
import {Bars3 as Menu} from "@styled-icons/heroicons-outline";
import styles from '../styles/Header.module.scss';
import Image from "next/image";

export default function Header ()  {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      {mobileMenuOpen &&
        <MobileMenu
          closeHandler={setMobileMenuOpen}
        />
      }
      <div className={`bg-white bg-opacity-80 absolute z-40 top-14 w-full`}>
        <div className="container mx-auto grid grid-cols-16 z-10">
          <div className={`${styles.mainNav} hidden lg:col-start-3 lg:col-span-4 lg:flex items-center lg:visible`}> {/* 2 + 4 + 5 + 3 + 3 + 4 + 1*/}
            <nav className="w-full">
              <ul className="flex flex-row justify-around">
                <li><ActiveLink href="/">Home</ActiveLink></li>
                <li><ActiveLink href="/shop">Shop</ActiveLink></li>
                <li><ActiveLink href="/weddings">Weddings</ActiveLink></li>
              </ul>
            </nav>
          </div>
          <div className="logo col-span-5 lg:col-span-3 flex justify-center">
            <Image
              className="-my-6"
              src="/images/logo.png"
              alt="Bespoke Blossoms Logo"
              width={175}
              height={175}
            />
          </div>
          <div className={`${styles.mainNav} flex items-center col-span-3 invisible lg:visible`}>
            <nav className="w-full">
              <ul className="flex flex-row justify-around">
                <li><ActiveLink href="/about">About</ActiveLink></li>
                <li><ActiveLink href="/contact">Contact</ActiveLink></li>
              </ul>
            </nav>
          </div>
          <div className="flex items-center col-span-8 lg:col-span-4">
            <CartIcon />
            <div className="lg:hidden ml-10 mr-5">
              <Menu
                className={styles.hamburgerIcon}
                size={35}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import React from 'react';
import Link from "next/link";
import Head from "next/head";

const Failure = () => {
  return (
    <main className="contentOffset container mx-auto">
      <Head>
        <title>Error | Bespoke Blossoms</title>
      </Head>
      <div className="w-3/4 mx-auto text-center">
        <h2>Something went wrong here</h2>
        <p>
          You have found a glitch in our system. This one's not your fault. We know that this can be frustrating and we
          apologize for any inconvenience this might cause. A report of the issue has already been automatically sent
          to our team and we hope we can resolve the issue soon.
        </p>
      </div>

      <h3>In the meantime...</h3>
      <div className="mb-10">
        <ul>
          <li>You might be able to resolve the issue by refreshing the page. </li>
          <li>If that doesn't work, please feel free to call our shop at <Link href="tel:2503820243">250-382-0243</Link> to place your order.</li>
          <li>Feel free to <Link href="/contact">reach out</Link> to us to get help using the website.</li>
        </ul>
      </div>
      <div>
        <p>
          We strive to create a seamless shopping experience for you. Thank you for your patronage and your patience
          as we work towards resolving the issue.
        </p>
      </div>
    </main>
  )
}

export default Failure;